.time{
    margin-top: 30px;
    padding: 0 50px;
    &-tabs{
        &-item{
            width: 140px;
            height: 28px;
            line-height: 28px;
            background-color: rgba(233, 88, 47, 1);
            color:white;
            display: inline-block;
            text-align: center;
            cursor: pointer;
        }
    }
    &-step{
        margin-top: 20px;
        font-size: 15px;
        font-weight: 600;
        &-item{
            margin-bottom: 20px;
            .title{
                color:  #E9582F;
                margin-bottom: 10px;
            }
            .content{
    
            }
        }

    }
}
@color-bright-1: #FFFFFF;@color-blue-1: #F9FAFF;@color-blue-2: #6C72F3;@color-blue-3: #90a4fe;@color-blue-4: #BDC7FC;@color-blue-5: #2E3AEB;@color-blue-6: #D7DEFE;@color-dark-0: #D6D6D6;@color-dark-1: #3d4757;@color-dark-2: #6B6B6B;@color-dark-3: #555555;@color-dark-4: #434343;@color-dark-5: #29292c;@color-dark-6: #010002;@color-dark-7: #707070;@color-red: #FF0B00;@color-green: #028E19;@font-size-base: 16px;@font-size-lg: 18px;@font-size-sm: 14px;
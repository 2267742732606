// Color
// @color-bright-1: #FFFFFF;

// @color-blue-1: #F9FAFF;
// @color-blue-2: #E9ECFB;
// @color-blue-3: #D7DEFE;
// @color-blue-4: #BDC7FC;
// @color-blue-5: #2E3AEB;


// @color-dark-0: #D6D6D6;
// @color-dark-1: #7D7D7D;
// @color-dark-2: #6B6B6B;
// @color-dark-3: #555555;
// @color-dark-4: #434343;
// @color-dark-5: #29292c;
// @color-dark-6: #010002;

// @color-red: #FF0B00;
// @color-green: #028E19;

// Background
@background-color-primary: @color-blue-2;
@background-color-secondary: @color-blue-2;
@background-color-tertiary: @color-blue-5;
@background-body: @color-blue-1;

body {
  background-color: @background-body;
}

// Font Color
// @font-color-default: @color-dark-1;
@font-color-default: #323232;
@font-color-primary: @color-dark-4;
@font-color-secondary: @color-dark-3;
@font-color-active: #FFFFFF;

@font-family: 'MicrosoftYaHeiLight', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';

@text-color: @font-color-default;

// Base Scaffolding Variables
// ---
// @font-size-base: 16px;
// @font-size-lg: 18px;
// @font-size-sm: 14px;

// Border
@border-radius-base: 5px;
@border-color-base: @color-blue-3; // base border outline a component


// Layout
@layout-body-background: @background-body;
@layout-header-background: @color-bright-1;
@layout-header-height: 64px;
@layout-header-padding: 15px 10px;

.ant-layout {

  &-header {
    box-sizing: border-box;
  }

  &-sider {
    padding-right: 1px;
  }

  &-content {
    // height: calc(100vh - 80px);
    height: calc(100vh - 124px);
    overflow: hidden;
    overflow-y: auto;
  }
}

// Menu
// ---
@menu-selected-bg: @color-blue-2;
@menu-item-active-bg: @color-blue-2;
@menu-highlight-color: @font-color-active;
@menu-item-font-size: @font-size-sm;
@menu-collapsed-width: 80px;
@menu-inline-toplevel-item-height: 45px;

// 默认菜单样式
.ant-menu {
  font-weight: 400;
  border-right: none;
  font-size: @font-size-base;
  transition: width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s !important;

  &-item-active {
    background-color: @menu-item-active-bg;
  }

  .anticon {
    font-size: @font-size-base;
  }

  &-submenu &-item {
    font-size: @font-size-sm;
  }

  // 菜单栏选择下样式
  &-submenu-selected {
    .ant-menu-submenu-title {
      background: @menu-selected-bg;
    }

    .ant-menu-submenu-arrow {
      &::after {
        background-image: none !important;
        background-color: @menu-highlight-color  !important;
      }

      &::before {
        background-image: none !important;
        background-color: @menu-highlight-color  !important;
      }
    }
  }

  // 菜单鼠标移入样式
  &-submenu-active {
    .ant-menu-submenu-title {
      background: @menu-selected-bg;
    }
  }

  // 菜单栏标题右侧图标
  &-submenu-arrow {
    transform: none !important;

    &::before {
      width: 4px !important;
      height: 1px !important;
      transform: none !important;
    }

    &::after {
      width: 4px !important;
      height: 1px !important;
      transform: rotate(90deg) translateX(0px) !important;
    }
  }

  // 菜单栏打开下样式
  &-submenu-open {
    .ant-menu-submenu-arrow {
      ::before {
        background-image: none !important;
        background-color: @menu-highlight-color  !important;
      }

      &::after {
        background: none !important;
      }
    }
  }

  //选中右侧竖杠
  &-inline {
    .ant-menu-item::after {
      border-right: 3px solid #BDC7FC;
    }
  }
}

.ant-menu-item,
.ant-menu-submenu-title {
  transition: cubic-bezier(0.645, 0.045, 0.355, 1), border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}

// Buttons
@btn-font-size: @font-size-base;
@btn-font-weight: 500;
@btn-border-radius: 16rpx;

@btn-default-color: @color-dark-2;
@btn-default-border: @color-blue-3;

@btn-primary-color: @color-dark-6;
@btn-primary-bg: @color-dark-0;

@btn-link-bg: @background-color-primary;
@btn-link-hover-bg: @background-color-tertiary;
@btn-link-color: @color-bright-1;
@btn-link-hover-color: @color-bright-1;

.ant-btn {
  font-size: @btn-font-size;
  font-weight: bold;
  line-height: @btn-font-size;
  padding: 6px 26px;
  min-width: 88px;
  vertical-align: middle;

  &:hover,
  &:active,
  &:focus {
    opacity: 0.8 !important;
    color: @btn-default-color;
    border-color: @btn-default-border;
  }

  &-primary {
    border: none;

    &:hover,
    &:active,
    &:focus {
      color: @btn-primary-color;
      background-color: @btn-primary-bg;
    }
  }

  &-link {
    background-color: @btn-link-bg;
    font-size: @font-size-sm;
    color: @btn-link-color;
    border: none;
    padding: 6px 16px;

    &[disabled],
    &[disabled]:hover,
    &[disabled]:focus,
    &[disabled]:active {
      // background: #90A4FE !important;
      background: #bdc7fc !important;
      color: #fff;
    }

    &:hover,
    &:active,
    &:focus {
      // background-color: @btn-link-hover-bg;
      background-color: @btn-link-bg;
      color: @btn-link-hover-color;
    }
  }

  &-round&-sm {
    height: 26px;
  }

  &+& {
    margin-left: 14px;
  }
}


// Tag
@tag-default-bg: @color-bright-1;
@tag-default-color: @color-blue-5;
@tag-font-size: @font-size-sm;
@tag-line-height: 1.5;
@tag-border-radius: 10px;
@tag-min-width: 90px;
@tag-text-align: center;
@tag-font-weight: bold;

@tag-close-icon-width: 16px;
@tag-close-icon-height: 16px;
@tag-close-icon-top: -6px;
@tag-close-icon-right: -6px;
@tag-close-icon-bg: red;
@tag-close-icon-color: @color-bright-1;
@tag-close-icon-size: 12px;

.ant-tag {
  border-radius: @tag-border-radius  !important;
  text-align: @tag-text-align;
  font-weight: @tag-font-weight;
  position: relative;
  margin: 0 5px;
  padding: 6px 16px;
  cursor: pointer;
  min-width: 90px;
  box-shadow: 0px 3px 3px 0px rgba(33, 34, 34, 0.12);

  &-close-icon {
    position: absolute;
    width: @tag-close-icon-width;
    height: @tag-close-icon-height;
    top: @tag-close-icon-top;
    right: @tag-close-icon-right;
    background-color: @tag-close-icon-bg;
    color: @tag-close-icon-color;
    border-radius: 50%;
    font-size: @tag-close-icon-size;
    padding: 2px;

    &:hover {
      color: @color-bright-1;
    }
  }

  &.tag-checked,
  &:hover,
  &:focus,
  &:active {
    background-color: @background-color-tertiary;
    color: @color-bright-1;
  }
}

// Table
@table-header-bg: @color-blue-2;
@table-header-color: #ffffff;
@table-header-weight: bold;
@table-body-weight: bold;
@table-font-size: 10px;
@table-selected-row-color: @font-color-active;
@table-selected-row-bg: #90A4FE;
@table-selected-row-hover-bg: #90A4FE;
// @table-row-hover-bg: @background-color-secondary;
@table-row-hover-bg: #90A4FE;
@table-padding-vertical: 15px;
@table-padding-horizontal: 6px;
@table-border-radius-base: 0px;
@tree-node-hover-bg: #ffffff;

.ant-table {
  font-size: @font-size-sm;
  font-weight: @table-body-weight;
  line-height: @font-size-sm;

  &-thead {
    >tr {
      >th {
        font-weight: @table-header-weight;
      }
    }
  }

  &-tbody {

    /*偶数行*/
    & tr:nth-of-type(even) {
      background: #fff;
    }

    /*奇数行*/
    & tr:nth-of-type(odd),
    & tr:nth-of-type(odd)>.ant-table-cell-fix-left,
    & tr:nth-of-type(odd)>.ant-table-cell-fix-right {
      background: #F5F7FF;
    }

    & tr.ant-table-row-selected>td {
      background: #90A4FE !important;
    }

    & tr:hover {
      cursor: pointer;
      color: #FFFFFF;
    }
  }


  &-row-selected &-cell {
    color: @font-color-active;
  }

  &-selection {
    justify-content: center;
  }
}


// Pagination
@pagination-item-min-width: 61px;
@pagination-item-mini-min-width: 40px;
@pagination-item-mini-margin-right: 6px;
@pagination-item-radius: 16px;

@pagination-item-size: 24px;

.ant-pagination {

  font-size: @font-size-sm;

  &-item,
  &.mini &-item {
    border-radius: 16px;

    &:hover,
    &:active,
    &:focus {
      border: none;
      background-color: @background-color-tertiary;

      a {
        color: @color-bright-1;
      }
    }
  }

  &-item {
    min-width: @pagination-item-min-width;
  }

  &.mini &-item {
    min-width: @pagination-item-mini-min-width;
    margin-right: @pagination-item-mini-margin-right;
  }

  &-item-active {
    border: none;
    background-color: @background-color-tertiary;

    &:hover a,
    &:focus a,
    &:active a,
    a {
      color: @color-bright-1;
    }
  }


  &-item-link {
    min-width: @pagination-item-min-width;
    border-radius: 16px !important;

    .anticon svg {
      display: none;
    }
  }

  &-next &-item-link,
  &-prev &-item-link,
  &.mini &-next &-item-link,
  &.mini &-prev &-item-link {
    position: relative;
    background-color: @background-color-primary;
    color: @color-bright-1;
    border: none;

    &::after {
      position: absolute;
      content: "上一页";
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }

    &:hover,
    &:focus,
    &:active {
      background-color: @background-color-tertiary;
      color: @color-bright-1;
    }
  }

  &.mini &-next,
  &.mini &-prev {
    min-width: @pagination-item-mini-min-width;
    margin-right: @pagination-item-mini-margin-right;
  }

  &-next &-item-link::after,
  &.mini &-next &-item-link::after {
    content: "下一页";
  }

  &-options {
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      min-height: 24px !important;
      height: 24px;
    }

    .ant-select-single .ant-select-selector .ant-select-selection-item {
      line-height: 24px;
    }

    .ant-select-arrow {
      top: 40%
    }
  }
}

// Input
@input-hover-border-color: @color-blue-4;
@outline-color: @color-blue-4;
@placeholder-color: @color-blue-4;
@input-affix-margin: 6px;
@input-height-base: 32px;
@input-border-color: @color-blue-3;

.ant-input {
  font-size: @font-size-sm;
  font-weight: 500;
  border-radius: 5px;
  padding: 5px;
  line-height: 22px;

  &-round&-affix-wrapper {
    border-radius: 16px;
    line-height: 18px;
    padding: 4px 11px;
  }

  &-affix-wrapper>& {
    padding: 1px;
  }

  &::placeholder {
    color: @placeholder-color;
  }

}

// Select
@select-item-selected-bg: @background-color-secondary;
@select-item-active-bg: @background-color-secondary;
@select-item-selected-color: @font-color-active;
@select-placeholder-color: @color-blue-4;
@select-dropdown-font-size: @font-size-sm;

.ant-select {
  font-size: @font-size-sm;
  font-weight: 500;
  border-radius: 5px;
  // width: 100vw;
  // min-width: 120px;
  width:200px;
  vertical-align: middle;
  min-height: 34px;

  &-single&-sm:not(.ant-select-customize-input) .ant-select-selector {
    height: 26px;
  }

  &:not(.ant-select-customize-input) .ant-select-selector {
    min-height: 34px;
    // min-width:212px;
  }

  &-round&:not(&-customize-input) &-selector {
    border-radius: 16px;
  }

  &-round &::placeholder {
    color: @placeholder-color;
  }


  &-selection-placeholder {
    color: @select-placeholder-color;
  }

  &-item-option-active:not(.ant-select-item-option-disabled) {
    color: #FFFFFF;
  }
}

//防止下拉框换行
.ant-select-selection-item {
  white-space: inherit;
}

// Picker
.ant-picker {
  width: 100%;
  font-size: @font-size-sm;
  padding: 4px 11px;
  vertical-align: middle;
  min-height: 34px;

  &-small {
    padding: 1px 11px;
  }

  &-round {
    border-radius: 16px;
  }

  &-input>input {
    font-size: @font-size-sm;

    &::placeholder {
      color: @placeholder-color;
    }
  }

  &-dropdown {
    font-size: @font-size-sm;
  }
}

// Checkbox
.ant-checkbox {
  &-inner {
    border-radius: 2px;
  }

  &-inner-text {
    font-size: @font-size-sm;
  }

}

// Drawer
@drawer-mask-bg: transparent;

.ant-drawer {
  &-mask {
    background: @drawer-mask-bg;
  }

  &-header {
    padding: 14px 17px;
  }

  &-close {
    padding: 17px;
  }

  &-title {
    text-align: left;
  }

  &-body {
    padding: 14px 17px;
  }

  &-content-wrapper {
    padding-left: 30px;
    background: rgba(0, 0, 0, 0.3);
  }
}


// Base

.money {
  font-size: @font-size-base;

  &-red {
    color: @color-red;
  }

  &-green {
    color: @color-green;
  }

  &-blue {
    color: @color-blue-5;
  }
}

.statu {
  font-size: @font-size-base;

  &-red {
    color: @color-red;
  }

  &-green {
    color: @color-green;
  }

  &-blue {
    color: @color-blue-5;
  }

  &-replenish {
    color: #D9001B;
  }
}


//color
.color-blue {
  color: @color-blue-5;
}

.text-line {
  text-decoration: underline;
}

//form
//表单项
@form-item-margin-bottom: 20px;
// 失效色
@disabled-color: @color-dark-7;
// 主文本色
@text-color: #323232;
//label字体大小
@form-item-label-font-size: @font-size-sm;
//label字体颜色
@label-color: @color-dark-1;

//form 警告文字
.ant-form-item-explain.ant-form-item-explain-error {
  font-size: 12px !important;
}


@zindex-modal: 10;
@zindex-modal-mask: 10;
@zindex-message: 10;
@zindex-notification: 10;


//栅格 5列
.iApp-ant-col-5 {
  display: block;
  flex: 0 0 20%;
  max-width: 20%;
}

//自定义label 样式
.label-name {
  display: inline-block;
  padding: 0 10px;
  line-height: 2;
  white-space: nowrap;
}

//设置select 输入变的时候最小值
.min-width-select {
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    min-width: 212px !important;
  }
}

//td 按钮容器
.td-wrap {
  padding: 0 !important;
}


//增加编辑表格 鼠标滑过样式
.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  min-height: 30px;
  line-height: 22px;
  padding: 3px;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid #bfbfbf;

  span {
    padding: 0;
  }
}

// .editable-row:hover .editable-cell-value-wrap {
//   border: 1px solid #d9d9d9;
//   border-radius: 4px;
//   padding: 3px;
// }

// [data-theme='dark'] .editable-row:hover .editable-cell-value-wrap {
//   border: 1px solid #434343;
// }

//修改可编辑表格的 padding
.yh-edit-table-tr {
  &>td {
    padding: 6px !important;
  }
}
.home-box{
    position: fixed;
	top: 0;
	left: 0;
	z-index: 10;
	height: 100vh;
    min-width: 1440px;
    background:white;
    overflow-y: auto;
    background-size: 100% 100%;
}
.home {
    display: flex;
    width: 100%;
    min-height: 100vh;
    position: relative;
    overflow: hidden;
    &-left{
        width: 747px;
        background: url('../../assets/bg.png') no-repeat;
        background-size: 100% 100%;
        padding-bottom:69px;
        .left-login {
            margin: 24px 0 0 18px;
            box-sizing: border-box;
            height: 60px;
            img{
                height:100%;
                vertical-align: text-bottom
            }
            span{
                display:inline-block;
                font-size: 28px;
                color: #FD8700;
            }
        }
        .left-amount{
            margin: 0 auto;
            margin-top: 46px;
            margin-bottom: 94px;
            display: flex;
            justify-content: space-around;
            .amount-item{
                padding:60px 0 45px 0;
                box-sizing: border-box;
                width: 200px;
                height: 274px;
                background: url('../../assets/box-border.png') no-repeat;
                background-size: 100% 100%;
                display: flex;
                flex-direction: column;
                justify-content:space-between;
                align-items: center;

                &-num{
                    font-size: 100px;
                    font-weight: bold;
                    line-height: 1;
                }
                &-size{
                    font-size: 35px;
                    font-weight: 400;
                    color: #454646;
                    line-height: 1;
                }
            }
            .amount-item:nth-child(1) .amount-item-num{
                color: #FD8700;
            }
            .amount-item:nth-child(2) .amount-item-num{
                color: #5B8CFF;
            }
            .amount-item:nth-child(3) .amount-item-num{
                color: #00B7EE;
            }
        }
        .left-qr{
            width: 459px;
            height: 503px;
            padding-top: 53px;
            background: #5B8CFF;
            text-align: center;
            margin:0 auto;
            p{
                width: 357px;
                height: 366px;
                line-height: 366px;
                text-align: center;
                background-color: white;
                margin:0 auto;
                margin-bottom: 19px;
                padding-top: 13px;
                span{
                    color:#333;
                }
            }
            span{

                font-size: 35px;
                line-height: 1;
                color: #FFDEDC;
            }
        }

    }
    &-right{
        width: calc(100vw - 747px);
        padding: 171px 0 0 144px;
        // padding: 85px 0 0 70px;
        box-sizing: border-box;
        .person{
            height: 137px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;        
            margin-bottom: 137px;
            // margin-bottom: 60px;
            &-item{
                font-size: 50px;
                line-height: 50px;
                font-weight: bold;
                padding: 0;
                margin: 0;
                display: flex;
                cursor: pointer;
               .left{

               }     
               .right{
                color:#5B8CFF
               }
            }
        }
        .menu{
            width: 895px;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            &-item{
                width: 406px;
                height: 201px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #FD8700;
                border-radius: 10px;
                margin-bottom: 80px;
                line-height: 1;
                text-align: center;
                color: #FFFFFF;
                font-size: 80px;
                position: relative;
            }
            &-num{
                width: 50px;
                height: 50px;
                border-radius: 50%;
                background:red !important;
                // color: blue !important;
                display:block;
                font-size:34px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: 700;
                line-height: 50px !important;
                position: absolute;
                top: -20px ;
                right: -20px;
            }
            .none-margin{
                margin-bottom: 0px;
            }
        }
    }
}


.login-shop-container {
	width: 100%;
	position: relative;

	span {
		display: block;
		margin-bottom: 10px;
		color: #eee;
		background: #108ee9;
		border-color: #adc6ff;
		border: 1px solid #d9d9d9;
		border-radius: 5px;
		text-align: center;
		padding: 6px 0px;
		cursor: pointer;
	}
}

//登陆的样式覆盖
.login-shop-modal {
	.ant-modal-confirm-btns {
		display: none;
	}

	.ant-modal-confirm-content {
		margin-left: 0px !important;
	}
}
@color-bright-1: #FFFFFF;@color-blue-1: #F9FAFF;@color-blue-2: #6C72F3;@color-blue-3: #90a4fe;@color-blue-4: #BDC7FC;@color-blue-5: #2E3AEB;@color-blue-6: #D7DEFE;@color-dark-0: #D6D6D6;@color-dark-1: #3d4757;@color-dark-2: #6B6B6B;@color-dark-3: #555555;@color-dark-4: #434343;@color-dark-5: #29292c;@color-dark-6: #010002;@color-dark-7: #707070;@color-red: #FF0B00;@color-green: #028E19;@font-size-base: 16px;@font-size-lg: 18px;@font-size-sm: 14px;
.right{
    display: flex;
}
.release{
    height: 650px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.meal{
    width: 100%;

    &-type{
        width: 246px;
        height: 63px;
        margin: 0 auto;
        border: 2px solid #6C72F3;
        border-radius: 10px;
        font-size: 18px;
        font-weight: bold;
        color: #6C72F3;
        line-height: 63px;
        text-align: center;
        margin-bottom: 15px;
        overflow: hidden;
        cursor: pointer;
    }
    &-active{
        background: #6C72F3;
        color:white;
    }
}
.food{
    margin-left: 18px;
    display: flex;
    flex-wrap: wrap;
    &-item{
        width: 229px;
        height: 303px;
        margin-right: 13px;
        margin-bottom: 15px;
        background: #6C72F3;
        border-radius: 5px;
        overflow: hidden;
        cursor: pointer;
        .img{
            height: 208px;
            width: auto;
            position: relative;
            &-detail{
                height: 208px;
                width: 100%;
            }
            &-check{
                position: absolute;
                top: 7px;
                right: 7px;
                z-index: 999;
            }
        }
        .message{
            height: 95px;
            padding-top: 12px;
            box-sizing: border-box;
            color: white;
            .name{
                margin-left: 11px;
                font-size: 26px;
                line-height: 1;
            }
            .other{
                font-size: 18px;
                margin-top: 22px;
                padding: 0 46px 0 7px;
                box-sizing: border-box;
                display: flex;
                justify-content: space-between;
                line-height: 1;
            }
        }
    }
}
.login-shop-container {
	width: 100%;
	position: relative;

	span {
		display: block;
		margin-bottom: 10px;
		color: #eee;
		background: #108ee9;
		border-color: #adc6ff;
		border: 1px solid #d9d9d9;
		border-radius: 5px;
		text-align: center;
		padding: 6px 0px;
		cursor: pointer;
	}
}

//登陆的样式覆盖
.login-shop-modal {
	.ant-modal-confirm-btns {
		display: none;
	}

	.ant-modal-confirm-content {
		margin-left: 0px !important;
	}
}
@color-bright-1: #FFFFFF;@color-blue-1: #F9FAFF;@color-blue-2: #6C72F3;@color-blue-3: #90a4fe;@color-blue-4: #BDC7FC;@color-blue-5: #2E3AEB;@color-blue-6: #D7DEFE;@color-dark-0: #D6D6D6;@color-dark-1: #3d4757;@color-dark-2: #6B6B6B;@color-dark-3: #555555;@color-dark-4: #434343;@color-dark-5: #29292c;@color-dark-6: #010002;@color-dark-7: #707070;@color-red: #FF0B00;@color-green: #028E19;@font-size-base: 16px;@font-size-lg: 18px;@font-size-sm: 14px;
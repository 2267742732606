.toolbar {
	padding: 3px 12px;
	display: flex;
	flex-flow: row nowrap;

	.modules-name {
		display: inline-block;
		font-weight: bold;
		color: #7d7d7d;
		min-width: 120px;
		margin-right: 15px;
		padding-top: 6px;
	}

	/*订单搜索*/

	.pub-search-box {
		display: inline-block;
		flex: 1;
		vertical-align: top;
	}

	.pub-search-right {
		display: inline-block;
		margin-left: 14px;
	}

}
@color-bright-1: #FFFFFF;@color-blue-1: #F9FAFF;@color-blue-2: #6C72F3;@color-blue-3: #90a4fe;@color-blue-4: #BDC7FC;@color-blue-5: #2E3AEB;@color-blue-6: #D7DEFE;@color-dark-0: #D6D6D6;@color-dark-1: #3d4757;@color-dark-2: #6B6B6B;@color-dark-3: #555555;@color-dark-4: #434343;@color-dark-5: #29292c;@color-dark-6: #010002;@color-dark-7: #707070;@color-red: #FF0B00;@color-green: #028E19;@font-size-base: 16px;@font-size-lg: 18px;@font-size-sm: 14px;
.container-login {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1000;
	background: url('../../assets/images/bg1.jpg') no-repeat;
	background-size: 100% 100%;

	&::after {
		content: " ";
		width: 100vw;
		height: 100vh;
		background: rgba(0, 0, 0, 0.4);
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
	}
}


.login-warp {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 1126px;
	height: 645px;
	transform: translateX(-50%) translateY(-50%);
	border-radius: 0 80px 0 80px;
	overflow: hidden;

	&>div {
		float: left;
		width: 563px;
		height: 645px;
	}

	.bg1 {
		padding: 30px;
		background: url('../../assets/images/bg3.png');
		background-size: 100% 100%;

		.login-logo {
			width: 210px;
		}

		p {
			color: #fff;
			font-size: 20px;
			margin-top: 22px;
		}

		p:last-child {
			margin-top: 14px;
			font-size: 16px;
		}
	}

	.input-box {
		position: relative;
		padding-top: 51px;
		background: #fff;

		.input-title {
			padding-left: 63px;
			font-size: 20px;
			font-family: Microsoft YaHei, serif;
			color: #070223;
			margin-bottom: 157px;
		}

		&>p {
			margin-left: 50%;
			transform: translateX(-50%);
			width: 372px;
			height: 40px;
			border: 1px solid #eee;
			border-radius: 37px;

			&>span {
				float: left;
				width: 10%;
				height: 100%;

				img {
					display: block;
					height: 20px;
					margin: 9px 0 10px 15px;
				}
			}

			&>input {
				float: left;
				width: 75%;
				height: 100%;
				outline: none;
				background: none;
				border: none;
			}
		}

		p.account {
			margin-bottom: 45px;
		}

		p.password {
			margin-bottom: 24px;
		}

		.login-input-three {
			width: 372px;
			height: 40px;
			text-align: right;
			margin: 0 auto 64px;

			&>span {
				display: inline-block;
				font-size: 12px;
				color: #070223;

				input {
					cursor: pointer;
					vertical-align: middle;
				}
			}
		}

		.clear {
			clear: both;
		}

		.login-but {
			background-color: #99A4E1;
			text-align: center;
			line-height: 40px;
			font-size: 16px;
			color: #fff;
			cursor: pointer;
		}
	}
}

.login {
	width: 100%;
	height: 100%;
	background-image: linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%);

	.caseBlanche {
		background-color: #4498c4;
		height: 100vh;
		width: 100%;
		position: relative;
		z-index: 22;
	}

	.caseBlanche .load {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%);
		color: #fff;
		font-family: calibri;
		text-align: center;

		p {
			margin: 0;
		}
	}

	.rond {
		height: 100px;
		width: 100px;
		border: 1px solid #fff;
		border-radius: 50%;
		position: absolute;
		top: 50%;
		left: 50%;
		margin: -50px -50px;
		animation: rond 2s infinite;
		-webkit-animation: rond 2s infinite;
	}

	@keyframes rond {
		0% {
			transform: rotate(0deg);
		}

		100% {
			transform: rotate(360deg);
		}
	}

	@-webkit-keyframes rond {
		0% {
			-webkit-transform: rotate(0deg);
		}

		100% {
			-webkit-transform: rotate(360deg);
		}
	}

	.test {
		height: 10px;
		width: 10px;
		position: absolute;
		background-color: #fff;
		border-radius: 50%;
		top: 8px;
		left: 10px;
	}
}

.login-shop-container {
	width: 100%;
	position: relative;

	span {
		display: block;
		margin-bottom: 10px;
		color: #eee;
		background: #108ee9;
		border-color: #adc6ff;
		border: 1px solid #d9d9d9;
		border-radius: 5px;
		text-align: center;
		padding: 6px 0px;
		cursor: pointer;
	}
}

//登陆的样式覆盖
.login-shop-modal {
	.ant-modal-confirm-btns {
		display: none;
	}

	.ant-modal-confirm-content {
		margin-left: 0px !important;
	}
}


.caseMarronFonce {
	position: absolute;
	top: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.5);
	height: 100%;
	width: 100%;
}

.spinner {
	position: absolute;
	left: 50%;
	top: 50%;
	height: 80px;
	width: 80px;
	margin-top: -40px;
	margin-left: -40px;
	border: 3px #1890ff groove;
	border-left-color: transparent;
	border-right-color: transparent;
	border-radius: 50%;
	animation: spin 2s infinite;
	-webkit-animation: spin 2s infinite;
}

.spinner-text {
	position: absolute;
	left: 44%;
	top: 60%;
	z-index: 222;
	color: #eee;
}

@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
	}

	50% {
		-webkit-transform: rotate(360deg);
	}

	100% {
		-webkit-transform: rotate(0deg);
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	50% {
		transform: rotate(360deg);
	}

	100% {
		transform: rotate(0deg);
	}
}
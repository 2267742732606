.ant-tags {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
            font-feature-settings: 'tnum';
    display: inline-block;
    height: auto;
    margin-right: 8px;
    padding: 0 7px;
    font-size: 12px;
    line-height: 20px;
    white-space: nowrap;
    background: #fafafa;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    cursor: default;
    opacity: 1;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    cursor: pointer;
  }
  .ant-tags:hover {
    opacity: 0.85;
  }
  .ant-tags,
  .ant-tags a,
  .ant-tags a:hover {
    color: rgba(0, 0, 0, 0.85);
  }
  .ant-tags > a:first-child:last-child {
    display: inline-block;
    margin: 0 -8px;
    padding: 0 8px;
  }
  .ant-tags-close-icon {
    margin-left: 3px;
    color: rgba(0, 0, 0, 0.45);
    font-size: 10px;
    cursor: pointer;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
  .ant-tags-close-icon:hover {
    color: rgba(0, 0, 0, 0.85);
  }
  .ant-tags-has-color {
    border-color: transparent;
  }
  .ant-tags-has-color,
  .ant-tags-has-color a,
  .ant-tags-has-color a:hover,
  .ant-tags-has-color .anticon-close,
  .ant-tags-has-color .anticon-close:hover {
    color: #fff;
  }
  .ant-tags-checkable {
    background-color: transparent;
    border-color: transparent;
    cursor: pointer;
  }
  .ant-tags-checkable:not(.ant-tags-checkable-checked):hover {
    color: #1890ff;
  }
  .ant-tags-checkable:active,
  .ant-tags-checkable-checked {
    color: #fff;
  }
  .ant-tags-checkable-checked {
    background-color: #1890ff;
  }
  .ant-tags-checkable:active {
    background-color: #096dd9;
  }
  .ant-tags-hidden {
    display: none;
  }
  .ant-tags-pink {
    color: #eb2f96;
    background: #fff0f6;
    border-color: #ffadd2;
  }
  .ant-tags-pink-inverse {
    color: #fff;
    background: #eb2f96;
    border-color: #eb2f96;
  }
  .ant-tags-magenta {
    color: #eb2f96;
    background: #fff0f6;
    border-color: #ffadd2;
  }
  .ant-tags-magenta-inverse {
    color: #fff;
    background: #eb2f96;
    border-color: #eb2f96;
  }
  .ant-tags-red {
    color: #f5222d;
    background: #fff1f0;
    border-color: #ffa39e;
  }
  .ant-tags-red-inverse {
    color: #fff;
    background: #f5222d;
    border-color: #f5222d;
  }
  .ant-tags-volcano {
    color: #fa541c;
    background: #fff2e8;
    border-color: #ffbb96;
  }
  .ant-tags-volcano-inverse {
    color: #fff;
    background: #fa541c;
    border-color: #fa541c;
  }
  .ant-tags-orange {
    color: #fa8c16;
    background: #fff7e6;
    border-color: #ffd591;
  }
  .ant-tags-orange-inverse {
    color: #fff;
    background: #fa8c16;
    border-color: #fa8c16;
  }
  .ant-tags-yellow {
    color: #fadb14;
    background: #feffe6;
    border-color: #fffb8f;
  }
  .ant-tags-yellow-inverse {
    color: #fff;
    background: #fadb14;
    border-color: #fadb14;
  }
  .ant-tags-gold {
    color: #faad14;
    background: #fffbe6;
    border-color: #ffe58f;
  }
  .ant-tags-gold-inverse {
    color: #fff;
    background: #faad14;
    border-color: #faad14;
  }
  .ant-tags-cyan {
    color: #13c2c2;
    background: #e6fffb;
    border-color: #87e8de;
  }
  .ant-tags-cyan-inverse {
    color: #fff;
    background: #13c2c2;
    border-color: #13c2c2;
  }
  .ant-tags-lime {
    color: #a0d911;
    background: #fcffe6;
    border-color: #eaff8f;
  }
  .ant-tags-lime-inverse {
    color: #fff;
    background: #a0d911;
    border-color: #a0d911;
  }
  .ant-tags-green {
    color: #52c41a;
    background: #f6ffed;
    border-color: #b7eb8f;
  }
  .ant-tags-green-inverse {
    color: #fff;
    background: #52c41a;
    border-color: #52c41a;
  }
  .ant-tags-blue {
    color: #1890ff;
    background: #e6f7ff;
    border-color: #91d5ff;
  }
  .ant-tags-blue-inverse {
    color: #fff;
    background: #1890ff;
    border-color: #1890ff;
  }
  .ant-tags-geekblue {
    color: #2f54eb;
    background: #f0f5ff;
    border-color: #adc6ff;
  }
  .ant-tags-geekblue-inverse {
    color: #fff;
    background: #2f54eb;
    border-color: #2f54eb;
  }
  .ant-tags-purple {
    color: #722ed1;
    background: #f9f0ff;
    border-color: #d3adf7;
  }
  .ant-tags-purple-inverse {
    color: #fff;
    background: #722ed1;
    border-color: #722ed1;
  }
  .ant-tags-success {
    color: #52c41a;
    background: #f6ffed;
    border-color: #b7eb8f;
  }
  .ant-tags-processing {
    color: #1890ff;
    background: #e6f7ff;
    border-color: #91d5ff;
  }
  .ant-tags-error {
    color: #f5222d;
    background: #fff1f0;
    border-color: #ffa39e;
  }
  .ant-tags-warning {
    color: #fa8c16;
    background: #fff7e6;
    border-color: #ffd591;
  }
  .ant-tags > .anticon + span,
  .ant-tags > span + .anticon {
    margin-left: 7px;
  }
  .ant-tags.ant-tags-rtl {
    margin-right: 0;
    margin-left: 8px;
    direction: rtl;
    text-align: right;
  }
  .ant-tags-rtl .ant-tags-close-icon {
    margin-right: 3px;
    margin-left: 0;
  }
  .ant-tags-rtl.ant-tags > .anticon + span,
  .ant-tags-rtl.ant-tags > span + .anticon {
    margin-right: 7px;
    margin-left: 0;
  }
  .yh-tag-disabled{
    cursor: no-drop;
    background: #919292;
    border-color: #919292;
    color:white
    
  }
@color-bright-1: #FFFFFF;@color-blue-1: #F9FAFF;@color-blue-2: #6C72F3;@color-blue-3: #90a4fe;@color-blue-4: #BDC7FC;@color-blue-5: #2E3AEB;@color-blue-6: #D7DEFE;@color-dark-0: #D6D6D6;@color-dark-1: #3d4757;@color-dark-2: #6B6B6B;@color-dark-3: #555555;@color-dark-4: #434343;@color-dark-5: #29292c;@color-dark-6: #010002;@color-dark-7: #707070;@color-red: #FF0B00;@color-green: #028E19;@font-size-base: 16px;@font-size-lg: 18px;@font-size-sm: 14px;